.trends-page-wrapper {
    min-height: 800px;
    padding: 1%;
}

.profile-card {
    max-width: 200px;
}

.trends-title {
    font-size: 18px;
}