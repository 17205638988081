.chart-controls-container {
    display: flex;
    flex-direction: column;
}
.optimizer-label {
    top: -12px !important;
}
.create-prediction-container {
    min-width: 600px;
    margin-left: 10%;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.result-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.charts-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.create-prediction-label {
    margin-bottom: 10px;
}
.controls-container {
    display: flex;
    flex-direction: row;
}
.chart-controls {
    display: flex;
    flex-direction: row;
}
.chart-wrapper {
    width: 100%;
}
.charts-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 500px;
    min-width: 500px;
}
.chart {
    width: 100%;
}
.spinner {
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
.symbols-container {
    width: 30%;
    margin-right: 20px;
}
.prediction-container-large {
    margin-top: 15px;
    border-color: #1976d2;
    border: 1px;
    /* border-style: dashed; */
    padding: 4px;
    display: block;
}
.prediction-container-small {
    margin-top: 15px;
    border-color: #1976d2;
    border: 1px;
    /* border-style: dashed; */
    padding: 4px;
    display: none;
}
.warning-container {
    margin-top: 15px;
}
.interval-wrapper {
    position: relative; 
    display: flex; 
    flex-direction: row; 
    align-items: flex-start; 
    font-size: 12px;
    margin-bottom: 10px;
}
.page-container {
    display: flex;
    padding: 1%;
    flex-direction: column;
}
.symbol-inner {
    font-size: 14px !important;
}
.symbol-outer {
    font-size: 17px !important;
}
.section-header {
    font-size: 20px !important;
}
.p-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 28px;
}
.p1-info {
    margin: 10px;
}
.arrow-up {
    color: green;
    font-weight: bold;
    font-size: 32px;
    vertical-align: middle;
    line-height: 0.5;
    margin: 5px;
}

.arrow-down {
    color: red;
    font-weight: bold;
    font-size: 42px;
    vertical-align: middle;
    line-height: 0.5;
    margin: 5px;
}

@media (min-width: 100px) and (max-width: 550px){
    .create-prediction-container {
        max-width: 250px;
        min-width: 100px;
        margin-left: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .prediction-container-large {
        margin-top: 15px;
        border-color: #1976d2;
        border: 1px;
        /* border-style: dashed; */
        padding: 4px;
        display: none;
    }
    .prediction-container-small {
        margin-top: 15px;
        border-color: #1976d2;
        border: 1px;
        /* border-style: dashed; */
        padding: 4px;
        display: block;
    }
    .result-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .charts-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .chart-controls-container {
        display: flex;
        flex-direction: column;
    }
    .chart-controls {
        margin-left: 0px;
    }
    .page-container {
        display: flex;
        padding: 2% 0 1% 0;
    }
    .symbols-container {
        /* width: 30%; */
        font-size: 10px;
    }
    .interval-container {
        left: 0px;
    }
    .chart-container {
        width: 100%;
    }
    .charts-wrapper {
        /* min-width: 200px;
        max-width: 450px; */
        min-width: 100%;
        max-width: 100%;
    }
    .chart {
        width: 100%;
    }
    .section-header {
        font-size: 18px !important;
        margin-left: 3px !important;
    }
    .symbol-inner {
        font-size: 12px !important;
    }
    .symbol-outer {
        font-size: 15px !important;
    }
}
@media (min-width: 551px) and (max-width: 750px) {
    .create-prediction-container {
        min-width: 300px;
        margin-left: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}
@media (min-width: 751px) and (max-width: 1500px) {
    .create-prediction-container {
        min-width: 400px;
        margin-left: 10px;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}
.url {
    text-decoration: underline;
}